:root {
  --color: #1c1c1c;
  --headerNavHeight: 60px;
}

button {
  border: none;
  font-size: 1.3rem;
  border-radius: 10px;
  padding: 5px;
  background-color: var(--color);
  color: white;
}