.TextEditor-header {
    background-color: #282c34;
    min-height: 5vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
    margin-bottom: 5vh;
    text-align: center;
    padding: 12px;
   }

.wrapper-class {
    padding: 1rem;
    border: 1px solid #eeeeee;
  }
.editor-class {
    background-color:rgb(233, 233, 233);
    padding: 1rem;
    border: 1px solid #ccc;
  }
.toolbar-class {
    border: 1px solid #ccc;
  }

/* .preview {
    padding: 1rem;
    margin-top: 1rem;
  } */